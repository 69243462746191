.App {
  width: 100%;
  height: auto;
  min-height: 100vh;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

body {
  padding: 0;
  margin: 0;
}

.admin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-color: #79969c;
  font-family: sans-serif;
}

h3 {
  
  margin-top: 0;
  margin-bottom: 2px;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 500px;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}

label {
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}

input,
textarea {
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
}

input[type="file"] {
  margin-top: 10px;
}

button {
  background-color: #0077cc;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #005fa3;
}

@media screen and (max-width: 800px) {
  form {
    width: 90%;
    padding: 20px;
  }
}
.products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 300px;
}

.product-image img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.product-info h3 {
  margin-bottom: 10px;
}

.product-info p {
  margin-bottom: 10px;
}

.product-info h4 {
  margin-bottom: 10px;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

button:focus {
  outline: none;
}
.seller-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.seller-info {
  margin: 20px;
  
}

.social-media {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.social-media a {
  color: #fff;
  background-color: #3b5998;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 400px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

input,
textarea {
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

button {
  padding: 10px 20px;
  background-color: #3b5998;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

button:hover {
  background-color: #2b3b6b;
}












