
/* Responsive layout */
.products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px;
}

.product-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px;
  overflow: hidden;
  width: 300px;
  transition: all 0.3s ease;
  position: relative;
}

.product-card:hover {
  transform: translateY(-10px);
}

.product-image img {
  height: 200px;
  object-fit: cover;
  width: 100%;
}

.product-info {
  padding: 20px;
}

h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #0d6ddb;
}

p {
  font-size: 1rem;
  line-height: 1.2;
  margin-bottom: 10px;
}

h4 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

button {
  background-color: #4caf50;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  padding: 10px;
  transition: all 0.3s ease;
}

button:hover {
  background-color: #3e8e41;
}

.carousel-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  height: 300px;
  position: relative;
  scroll-behavior:smooth;
}

.carousel-button {
  background-color: transparent;
  border: none;
  color: #0b5cb9;
  cursor: pointer;
  font-size: 2rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.left-button {
  left: 10;
}

.right-button {
  right: 10;
}

.carousel-container::-webkit-scrollbar {
  display: none;
}

.carousel-container img {
  flex: 0 0 auto;
  width: 100%;
  object-fit: cover;
  height: auto;
  scroll-snap-align: start;
}

.carousel-container > div {
  display: flex;
  flex-wrap: nowrap;
  animation: scroll 5s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}



/* Background gradient */
body {
  background: linear-gradient(to bottom right, #22d26c, #431fd4);
}
.delete-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: transparent;
  border: none;
  color: #0b5cb9;
  cursor: pointer;
  font-size: 1rem;
}
.price {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.price::before {
  content: "Ksh ";
}

.price::after {
  content: "";
}




























