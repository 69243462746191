/* Background gradient */
.container {
  background-image: url("navimage.jpg"); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 20px;
  border-radius: 10px;
}


@media screen and (max-width: 600px) {
  .container {
    flex-direction: column;
    height: auto;
    padding: 20px;
  }
}

h2 {
  font-size: 34px;
  color: #18c3d3;
  margin: 0;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  /* box-shadow: 2px 2px 5px rgba(241, 14, 14, 0.943); */
  }


  h4 {
    font-size: 34px;
    color: #1111d4;
    margin: 0;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 5px rgba(50, 66, 165, 0.943);
    }




  
  /* Logo h3 */
  h3 {
  font-size: 24px;
  color: #671b5b;
  margin: 0;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 5px rgba(241, 14, 14, 0.943);
  }
  
  h3:hover {
  background-image: linear-gradient(to right, #2575fc, #6a11cb);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
  
  /* Navbar links */
  .links {
  display: flex;
  justify-content: center;
  align-items: center;
  }
  
  .links a {
  color: #ffffff;
  font-size: 18px;
  text-decoration: none;
  padding: 10px;
  margin: 0 5px;
  /* box-shadow: 2px 2px 5px rgba(241, 14, 14, 0.943); */
  transition: all 0.3s ease-in-out;
  border-radius: 0px;
  }
  
  .links a:hover {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
  }
  
  /* Shopping cart icon */
  .links a:last-of-type {
  margin-right: 0;
  }
  
  /* Link to shopping cart */
  .links a:last-of-type svg {
  fill: #ffffff;
  vertical-align: middle;
  }
  
  .links a:last-of-type:hover svg {
  fill: #2575fc;
  }
  
  /* Media query for small screens */
  @media only screen and (max-width: 100px) {
  .links {
  flex-direction: column;
  }
  
  .links a {
  margin: 5px 0;
  }
  }
  
  /* Dropdown menu for mobile devices */
  @media only screen and (max-width: 500px) {
  .links {
  display:inline;
  flex-direction:row;
  position:-webkit-sticky;
  top: 80px;
  left: 0;
  /* background-color: #09585e; */
  box-shadow: 0px 0px 20px rgba(137, 173, 144, 0.219);
  }
  
  /* Show dropdown menu when hovering over navbar */
  .navbar:hover .links {
  display: flex;
  }
  }
  
  
  
  
  
  
  
  


























































































































































































































































































